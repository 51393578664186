import cookies from "js-cookie";
import { format, utcToZonedTime } from 'date-fns-tz'
import { parseISO, isValid, isSameYear, isSameMonth, isSameDay, formatDistanceToNowStrict } from 'date-fns'
import { enUS, enGB, enAU, af } from 'date-fns/locale'


type brightTalkDateTimeProps = {
    fromType: "summit" | "talk" | "series" | "episode-featured" | "episode-list" | "channel-filter" // to check from where the component being used
    dateTime: Date | number// dateTime is the start time of talks and summits
    status?: "upcoming" | "live" | "recorded" | "processing"
    duration?: number
    endDate?: Date // endDate is a optional one since it's require only for summits.
}

type dateTime = {
    dateTime: string,
    isGlobeIcon: boolean,
    timeZoneFull: string,
    pipeDivider: boolean,
    talkDate: string,
    talkTime: string,
}

/**
 * Function to check whether the time is recognized.
 * @param zoneName get the full timezone text
 * @returns boolean
 */
function hasRecognizedTimezone (zoneName: string): boolean {

    return !(zoneName.includes('+') || zoneName.includes('-'))

}

// Function to format the date and time and get it based on the local timezone.
function brightTalkFormatDatetime (datetime: string, timeZone: string, dateFormat: string) {

    let parsedDatetime;

    try {
        parsedDatetime = parseISO(datetime);
    }
    catch (e) {
        return '';
    }

    if(!isValid(parsedDatetime)) return ''; 

    return format(utcToZonedTime(parsedDatetime, timeZone), dateFormat, { timeZone, addSuffix: true, locale: brightTalkGetLocale(timeZone) })
        .replace('AM ', 'am ')
        .replace('PM ', 'pm ')

}

// Function to set the locale for the DATE-FNS.
function brightTalkGetLocale (timezone: string) {

    const validatedTimeZone = timezone.toLocaleLowerCase();

    if (validatedTimeZone.includes('america') || validatedTimeZone.includes('us/')) return enUS
    if (validatedTimeZone.includes('africa')) return af
    if (validatedTimeZone.includes('asia') || validatedTimeZone.includes('australia')) return enAU

    return enGB

}

export function brightTalkDateTime (fn:brightTalkDateTimeProps): dateTime {

    // logged-in user timezone
    const userTimeZone = cookies.get('BT_USER_TIMEZONE');

    // Get the user local TIMEZONE from the browser.
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let timeZone = '',
    finalDateTime = '',
    talkDate = '',
    talkTime = '',
    pipeDivider  = false,
    isIconNeeded = false,
    timeZoneFull = '';

    // Convert date time to string.
    const dateTimeString = fn.dateTime.toString();
    // Date time from date-fns
    const dateTime = new Date(dateTimeString);

    try {
        // Set the webcast data.
        talkDate = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy');

        // Check the fromType is talk or summit.
        if (fn.fromType === 'talk' || ['episode-featured', 'episode-list'].includes(fn.fromType)) {

            if (userTimeZone || typeof (userTimeZone) !== "undefined") {
                timeZone = userTimeZone
            } else if(localTimeZone) {
                timeZone = localTimeZone
            } else {
                timeZone = 'America/Los_Angeles';
            }

            /**
             * Check the talk status and based on that formatting the date and time
             * upcoming - (May 26 2023, 7:00pm BST)
             * on-demand - (45 mins ago | 22 mins)
             */
            if (fn.status === 'upcoming' || fn.status === 'live' || fn.status === 'processing') {

                // Get zone date and time from date-fns
                const zonedDateTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy, h:mma z');

                // Check the timezone is Recognized
                const isRecognized = hasRecognizedTimezone(zonedDateTime)

                if(isRecognized){
                    // Set the dateTime format for the recognized timezone.
                    finalDateTime = zonedDateTime;
                    // Set the webcast time.
                    talkTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'h:mma z');
                } else {
                    // Set the dateTime format for the unrecognized timezone.
                    finalDateTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy, h:mma');
                    // Set the true for isIconNeeded var to display the Globe Icon.
                    isIconNeeded = true;
                    // Set the full timezone to display the different globe icon and for tooltip text.
                    timeZoneFull = timeZone.replace("_", " ");
                    // Set the webcast time.
                    talkTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'h:mma');
                }

            } else {
                // Round the duration
                const formatDuration = Math.round(fn.duration / 60);

                if (fn.fromType === 'talk') {
                    // Get the date and time distance to now
                    const dateTimeDistance = formatDistanceToNowStrict(new Date(dateTimeString), {addSuffix: true})
                    talkDate = dateTimeDistance;
                }

                // The finalDateTime is removed and two new var created to pass into the object along with a bool check
                // for pipeDivider this allows us to run a conditional check in the cardsDataTime.tsx and display
                // merged finalDateTime or two sep vars split with a span markup divider
                pipeDivider = true;

                talkTime = formatDuration + (formatDuration > 1 ? " mins" : " min");
            }

          } else {

            const startDate = new Date(fn.dateTime);
            const endDate = new Date(fn.endDate);

            const checkIsSameYear = isSameYear( startDate, endDate );
            const checkIsSameMonth = isSameMonth( startDate, endDate );
            const checkIsSameDate = isSameDay( startDate, endDate );

            /**
             * Doing the following checks here for the summit date.
             * 1. Is the start and end dates (Day) are same - format (Jun 20 2023)
             * 2. Is the start and end dates (Month) are same - format (Jun 20-21 2023)
             * 3. Is the start and end dates (Years) are same - format (Jun 20 - Sep 21 2023)
             * Else display the date - format (Jun 20 2023 - Jun 21 2023)
             */

            if(checkIsSameDate) {
                finalDateTime = format(startDate, 'MMM dd yyyy');
            }else if(checkIsSameMonth){
                finalDateTime = format(startDate, 'MMM dd') + '-' + format(endDate, 'dd yyyy');
            } else if(checkIsSameYear){
                finalDateTime = format(startDate, 'MMM dd') + ' - ' + format(endDate, 'MMM dd yyyy');
            } else {
                finalDateTime = format(startDate, 'MMM dd yyyy') + ' - ' + format(endDate, 'MMM dd yyyy');
            }

          }

    }
    catch (e) {
        console.log(e)
    }

    return {
        pipeDivider: pipeDivider,
        talkDate: talkDate,
        talkTime: talkTime,
        dateTime: finalDateTime,
        isGlobeIcon: isIconNeeded,
        timeZoneFull: timeZoneFull
    }
}

export function channelFilterDateTime (fn:brightTalkDateTimeProps): dateTime {

    // logged-in user timezone
    const userTimeZone = cookies.get('BT_USER_TIMEZONE');
  
    // Get the user local TIMEZONE from the browser.
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    let timeZone = '',
    finalDateTime = '',
    talkDate = '',
    talkTime = '',
    pipeDivider  = false,
    isIconNeeded = false,
    timeZoneFull = '';
  
    // Convert the date and time and to ISO string.
    const dateTimeString = new Date(fn.dateTime).toISOString();
  
    try {
        // Set the webcast data.
        talkDate = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy');
  
        if (userTimeZone || typeof (userTimeZone) !== "undefined") {
            timeZone = userTimeZone
        } else if(localTimeZone) {
            timeZone = localTimeZone
        } else {
            timeZone = 'America/Los_Angeles';
        }
  
        /**
         * Check the talk status and based on that formatting the date and time
         * upcoming - (May 26 2023, 7:00pm BST)
         * on-demand - (45 mins ago | 22 mins)
         */
        if (fn.status === 'upcoming' || fn.status === 'live' || fn.status === 'processing') {
  
            // Get zone date and time from date-fns
            const zonedDateTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy, h:mma z');
  
            // Check the timezone is Recognized
            const isRecognized = hasRecognizedTimezone(zonedDateTime)
  
            if(isRecognized){
                // Set the dateTime format for the recognized timezone.
                finalDateTime = zonedDateTime;
                // Set the webcast time.
                talkTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'h:mma z');
            } else {
                // Set the dateTime format for the unrecognized timezone.
                finalDateTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'MMM dd yyyy, h:mma');
                // Set the true for isIconNeeded var to display the Globe Icon.
                isIconNeeded = true;
                // Set the full timezone to display the different globe icon and for tooltip text.
                timeZoneFull = timeZone.replace("_", " ");
                // Set the webcast time.
                talkTime = brightTalkFormatDatetime(dateTimeString, timeZone, 'h:mma');
            }
  
        } else {
            // Round the duration
            const formatDuration = Math.round(fn.duration / 60);
  
            if (fn.fromType === 'talk' || fn.fromType === 'channel-filter') {
                // Get the date and time distance to now
                const dateTimeDistance = formatDistanceToNowStrict(new Date(dateTimeString), {addSuffix: true})
                talkDate = dateTimeDistance;
            }
  
            // The finalDateTime is removed and two new var created to pass into the object along with a bool check
            // for pipeDivider this allows us to run a conditional check in the cardsDataTime.tsx and display
            // merged finalDateTime or two sep vars split with a span markup divider
            pipeDivider = true;
  
            talkTime = formatDuration + (formatDuration > 1 ? " mins" : " min");
        }
    }
    catch (e) {
        console.log(e)
    }
  
    return {
        pipeDivider: pipeDivider,
        talkDate: talkDate,
        talkTime: talkTime,
        dateTime: finalDateTime,
        isGlobeIcon: isIconNeeded,
        timeZoneFull: timeZoneFull
    }
}

