import React, { FC } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import {brightTalkDateTime, channelFilterDateTime} from '../../../libs/brightTalkDateTime/brightTalkDateTime'
import GlobalIcon from '../../GlobalIcon/GlobalIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import BtTooltip from '../../BtTooltip/BtTooltip'

interface dateTime {
  fromType: "summit" | "talk" | "series" | "episode-featured" | "episode-list" | "channel-filter" // to check from where the component being used
  dateTime: Date | number // dateTime is the start time of talks and summits
  status?: "upcoming" | "live" | "recorded" | "processing"
  duration?: number
  endDate?: Date // endDate is a optional one since it's require only for summits.
  id?: string
  pipeDivider?: boolean
  showTime?: boolean
}


const DateTime: FC<dateTime> = ({ id, fromType, dateTime, status, duration, endDate,showTime }) => {

  let isIconVisible = false, dateTimeData;

  if (fromType === 'channel-filter') {
    // Calling the function to get the formatted date and time based the TimeZone.
    dateTimeData = channelFilterDateTime( { status, dateTime, fromType, duration, endDate } );
  } else {
    // Calling the function to get the formatted date and time based the TimeZone.
    dateTimeData = brightTalkDateTime( { status, dateTime, fromType, duration, endDate } );
  }

  // Check the talk type and from type to display the Calender Icon.
  if(((status === 'upcoming' || status === 'live' || status === 'processing') && fromType !== 'episode-list') || fromType === 'summit') {
    isIconVisible = true
  }

  return (
    <>
      <time date-time={dateTime} className={styles[fromType + '-datetime']} data-bdd="webcast-datetime">
        { isIconVisible &&
          <FontAwesomeIcon className={ classnames(styles['cal-icon'], styles['bt-icons'] ) } icon={icon({name: 'calendar-day', style: 'light'})} />
        }

        { fromType === 'episode-list' ?
        <>
          <p data-bdd="episode-date-time">{dateTimeData.talkDate}</p>
          <span> { dateTimeData.talkTime }</span>
        </>
        : fromType === 'episode-featured' ?
        <span data-bdd="talks-date-time">{ dateTimeData.talkDate }
          <span>,</span> { dateTimeData.talkTime }
        </span>
        : dateTimeData.pipeDivider ?
        <span data-bdd="talks-date-time">{ dateTimeData.talkDate }
          { showTime==true &&
          <><span className={styles['text-divider']}>|</span> { dateTimeData.talkTime }</>
          }
        </span>
        : <span data-bdd="talks-date-time">{dateTimeData.dateTime}</span>
        }

        {/* Check the is globe icon needed for the timezone */}
        { dateTimeData.isGlobeIcon &&
          // Globe Icon and tooltip for timezone.
          <span id={id} className={classnames(styles['globe-icon'])} >
            <GlobalIcon timeZoneName={dateTimeData.timeZoneFull} />
            <BtTooltip id={id} timeZoneName={dateTimeData.timeZoneFull}/>
          </span>
        }

      </time>

    </>
  )

}

export default DateTime
